import IntlMessages from "../../../../helpers/intlMessages";
import React from "react";

export const  columnsOrganization = [

    {
        name: "",
        selector: "logo",
        sortable: false,
        filtrable: false,
        format: row => (row.logo ? <img
            src={row.logo}
            style={{width:"30px", height:"20px"}}
        /> : "")
    },
    {
        name: <IntlMessages
            id="setting.organization.designation"
        />,
        selector: "name",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="setting.common.description"
        />,
        selector: "description",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="setting.organization.web"
        />,
        selector: "web",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="setting.organization.secteur"
        />,
        selector: "entity.name",
        sortable: false,
        filtrable: true,
    },
    {
        name: <IntlMessages
            id="setting.organization.statut"
        />,
        selector: "actif",
        sortable: false,
        filtrable: false,
        format: row => (row.enable === true ? "Actif" : "Non Actif")
    }
];
